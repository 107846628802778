import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Flex, Typography, Image, Space } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import ragSvg from "assets/rag.svg";
import soc2Svg from "assets/soc2.svg";
import { useRouter } from "next/router";
import { RAG_ROUTE } from "templates/rag/rag.routes";

export const RagCard: React.FC = () => {
  const { serifFont } = useExtraToken();
  const router = useRouter();

  const onClick = () => {
    router.push(RAG_ROUTE.getHref());
  };

  return (
    <Card
      style={{
        backgroundColor: "#FFFFFF",
        height: "100%",
        position: "relative",
        cursor: "pointer",
      }}
      styles={{ body: { height: "100%", minHeight: 300 } }}
      onClick={onClick}
    >
      {/* Content */}

      <Flex vertical justify="space-between" style={{ height: "100%" }}>
        <div>
          <Flex justify="space-between" style={{ marginBottom: 8 }} align="start">
            <Typography.Title
              level={2}
              style={{ fontFamily: serifFont, fontWeight: 400, marginBottom: 0 }}
            >
              Enrich your own <br /> Synthetic Users
            </Typography.Title>
            <AntIcon component={() => <FontAwesomeIcon icon={faArrowRight} />} />
          </Flex>

          <Space>
            {Array.from({ length: 3 }).map((_, index) => (
              <Image
                key={index}
                preview={false}
                src={(ragSvg as { src: string }).src}
                width={50}
                height={50}
                alt={`Rag svg`}
              />
            ))}
          </Space>
        </div>

        <Typography.Paragraph style={{ maxWidth: "50%" }}>
          You have proprietary data that will set your Synthetic Users apart.
        </Typography.Paragraph>
      </Flex>

      {/* Image */}
      <div
        style={{
          position: "absolute",
          right: 30,
          bottom: 0,
        }}
      >
        <Image
          preview={false}
          src={(soc2Svg as { src: string }).src}
          width={120}
          height={120}
          alt={`soc2 svg`}
        />
      </div>
    </Card>
  );
};
