import { useAuthContext } from "logic/auth-context";
import MaintenanceTemplate from "pages/maintenance";
import { useMemo } from "react";
import { MainContent } from "./components/main-content";
import { SignInMobile } from "./components/sign-in-container";
import { TopBarMobile } from "./components/top-bar-mobile";

export function IndexContainerMobile(props: { isMaintenance: boolean }) {
  const auth = useAuthContext();

  const isSignedIn = useMemo(() => auth.session.user, [auth.session.user]);

  function Content() {
    return (
      <>
        <TopBarMobile />
        <div>{isSignedIn ? <MainContent /> : <SignInMobile />}</div>
      </>
    );
  }

  return props.isMaintenance ? <MaintenanceTemplate /> : <Content />;
}
