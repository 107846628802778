import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Flex, Typography, Image, Spin } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useExtraToken } from "components/ui-kit/core/extra-token";

export type InterviewModeCardProps = {
  title: string;
  interviewNumber: number;
  loading: boolean;
  description: string;
  onClick: () => void;
  imageSize?: number;
  imageSrc: string;
  imageOffset?: {
    x: number;
    y: number;
  };
};

export const InterviewModeCard: React.FC<InterviewModeCardProps> = ({
  title,
  interviewNumber,
  loading,
  description,
  onClick,
  imageSrc,
  imageSize = 200,
  imageOffset,
}) => {
  const { serifFont } = useExtraToken();

  const cssOverrides = `
  .interview-mode-card {
    background-color: #F6F6FF;
    height: 100%;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #DDDDEE;
    }
  }  
  `;

  return (
    <>
      <style>{cssOverrides}</style>
      <Spin spinning={loading}>
        <Card
          className="interview-mode-card"
          styles={{
            body: { height: "100%", minHeight: 300, display: "flex", flexDirection: "column" },
          }}
          onClick={onClick}
        >
          {/* Header */}
          <Flex justify="space-between" style={{ marginBottom: 8 }}>
            <Typography.Paragraph type="secondary" style={{ marginBottom: 0, fontSize: 12 }}>
              Interview type {interviewNumber}
            </Typography.Paragraph>
            <AntIcon component={() => <FontAwesomeIcon icon={faArrowRight} />} />
          </Flex>

          {/* Content */}
          <Flex justify="space-between" style={{ height: "100%", flexGrow: 1 }}>
            <Flex vertical justify="space-between" style={{ maxWidth: "50%" }}>
              <div>
                <Typography.Title
                  level={2}
                  style={{ fontFamily: serifFont, fontWeight: 400, marginBottom: 0 }}
                >
                  {title.split(" ")[0]} <br /> {title.split(" ")[1]}
                </Typography.Title>
                <Typography.Title
                  type="secondary"
                  level={2}
                  style={{ fontFamily: serifFont, fontWeight: 400, marginBlock: 0 }}
                >
                  interview
                </Typography.Title>
              </div>
              <Typography.Paragraph style={{ marginTop: "auto", marginBottom: 0 }}>
                {description}
              </Typography.Paragraph>
            </Flex>
          </Flex>

          {/* Image */}
          <div
            style={{
              position: "absolute",
              right: 0 - (imageOffset ? imageOffset?.x : 0),
              bottom: 0 - (imageOffset ? imageOffset?.y : 0),
            }}
          >
            <Image
              preview={false}
              src={imageSrc}
              width={imageSize}
              height={imageSize}
              alt={`${title} image`}
            />
          </div>
        </Card>
      </Spin>
    </>
  );
};
