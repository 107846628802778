import { useAuthContext } from "logic/auth-context";
import { CSSProperties, ReactNode, useMemo } from "react";
import { useSignupRequiredAlert } from "./signup-required-alert";

export function SignupRequiredTrigger(props: { children: ReactNode; style?: CSSProperties }) {
  const auth = useAuthContext();

  const signupRequiredAlert = useSignupRequiredAlert();

  const isSignedIn = useMemo(() => auth.session.user, [auth.session.user]);

  return (
    <>
      <style jsx global>{`
        .signup-required-trigger-enabled {
          cursor: pointer;
        }
        .signup-required-trigger-enabled > * {
          pointer-events: none;
        }
      `}</style>
      <div
        className={!isSignedIn ? "signup-required-trigger-enabled" : undefined}
        style={props.style}
        onClick={() => !isSignedIn && signupRequiredAlert.showSignupRequiredAlert()}
      >
        {props.children}
      </div>
    </>
  );
}
