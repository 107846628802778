import { useAuthContext } from "@/logic/auth-context";
import { INDEX_ROUTE } from "@/templates/index/index-routes";
import { AuthTabParams } from "@/templates/index/login";
import { Button } from "antd";
import { useRouter } from "next/router";

export const IndexTopBarActions = () => {
  const auth = useAuthContext();
  const router = useRouter();
  if (auth.session.user) return null;

  return (
    <div className="flex justify-end w-full">
      <Button
        type="primary"
        onClick={() => router.push(`${INDEX_ROUTE.getHref()}?login=${AuthTabParams.SignUp}`)}
      >
        Sign up / Log in
      </Button>
    </div>
  );
};
