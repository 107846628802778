import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Space, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";

export type HeaderCardProps = {
  text: string;
};

export const HeaderCard: React.FC<HeaderCardProps> = ({ text }) => {
  return (
    <Card
      bordered={false}
      style={{
        cursor: "pointer",
      }}
    >
      <Space align="center">
        <Typography.Paragraph
          style={{
            marginBottom: 0,
          }}
        >
          {text}
        </Typography.Paragraph>
        {text !== "" && <AntIcon component={() => <FontAwesomeIcon icon={faArrowRight} />} />}
      </Space>
    </Card>
  );
};
