import {
  faBookOpen,
  faGlobe,
  faGraduationCap,
  faMicroscope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography, Space, Row, Col } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import Link from "next/link";

export const TutorialsCard: React.FC = () => {
  const tutorialsData = [
    {
      description: "See how Synthetic Users fits into your research plan",
      icon: faBookOpen,
      url: "https://www.syntheticusers.com/science-posts/there-is-a-faster-and-more-accurate-way-to-do-research-use-synthetic-users",
    },
    {
      description: "What to do when you feel your Synthetic Users are being too generalist.",
      icon: faGlobe,
      url: "https://www.syntheticusers.com/post/what-to-do-when-you-feel-your-synthetic-users-are-being-too-generalist",
    },
    {
      description: "Get started with a 5 minute lesson ",
      icon: faGraduationCap,
      url: "https://www.syntheticusers.com/post/generating-running-and-sharing-synthetic-research-really",
    },
    {
      description: "Read up on the science behind Synthetic Users ",
      icon: faMicroscope,
      url: "https://www.syntheticusers.com/science",
    },
  ];

  return (
    <Card
      style={{
        backgroundColor: "#F5F5F5",
        height: "100%",
        position: "relative",
      }}
      styles={{ body: { height: "100%" } }}
    >
      {/* Content */}

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Typography.Title level={5} style={{ marginLeft: 24, fontWeight: 700 }}>
            Check out our tutorials section
          </Typography.Title>
        </Col>
        {tutorialsData.map((data, index) => (
          <Col span={12} key={index}>
            <Link href={data.url} rel="noopener noreferrer" target="_blank">
              <Space align="center" size="large">
                <AntIcon component={() => <FontAwesomeIcon size="2x" icon={data.icon} />} />
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                  {data.description}
                </Typography.Paragraph>
              </Space>
            </Link>
          </Col>
        ))}
      </Row>
    </Card>
  );
};
