import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Flex, Typography, Image, Badge } from "antd";
import researchPlanSvg from "assets/research-plan.svg";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import researchPlanCardBgSvg from "assets/research-plan-card-bg.svg";

const styles = `
  .study-plan-card {
    --card-bg-color: #ffffff;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-image: url(${(researchPlanCardBgSvg as { src: string }).src});
    background-size: cover;
    background-position: center;
  }

  .study-plan-card .ant-card-body {
    position: relative;
    z-index: 2;
  }

  .study-plan-card .rainbow-gradient {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #ff0000, #ff4000, #ff8000, #ffbf00, #ffff00, #80ff00, #00ff00, #00ffff, #0000ff, #4b0082, #8f00ff, #ff0000);
    background-size: 400% 400%;
    will-change: background-position;
    animation: moveGradient 20s ease infinite;
    opacity: 0.04;
    z-index: 1;
    mix-blend-mode: overlay;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .study-plan-card .rainbow-gradient {
      animation: none;
    }
  }

  @keyframes moveGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

type StudyPlanCardProps = {
  onClick: () => void;
};

export const StudyPlanCard: React.FC<StudyPlanCardProps> = ({ onClick }) => {
  const { serifFont } = useExtraToken();

  return (
    <>
      <style>{styles}</style>
      <Card
        className="study-plan-card"
        styles={{
          body: { height: "100%", minHeight: 300, display: "flex", flexDirection: "column" },
        }}
        onClick={onClick}
      >
        <div className="rainbow-gradient" />
        {/* Header */}
        <Flex justify="end" style={{ marginBottom: 8 }}>
          <AntIcon component={() => <FontAwesomeIcon icon={faArrowRight} />} />
        </Flex>

        {/* Content */}
        <Flex justify="space-between" style={{ height: "100%", flexGrow: 1 }}>
          <Flex vertical justify="space-between" style={{ maxWidth: "50%" }}>
            <div>
              <div className="flex items-center gap-0.5">
                <Typography.Title
                  level={2}
                  style={{ fontFamily: serifFont, fontWeight: 400, marginBottom: 0 }}
                >
                  Prisma{" "}
                </Typography.Title>
                <Typography.Text strong style={{ fontSize: 20, fontWeight: 400, marginTop: 6 }}>
                  ™
                </Typography.Text>
              </div>
              <div className="flex items-center gap-2">
                <Typography.Title
                  level={2}
                  type="secondary"
                  style={{ fontFamily: serifFont, fontWeight: 400, marginBottom: 0 }}
                >
                  multi-study planner
                </Typography.Title>
                <Badge status="success" count="Beta" style={{ backgroundColor: "green" }} />
              </div>
            </div>
            <Typography.Paragraph style={{ marginTop: "auto", marginBottom: 0 }}>
              Split complex research goals into crystal-clear studies.
              <br />
              Uncover perspectives you never knew you needed.
              <br />
              Transform scattered insights into coherent understanding.
            </Typography.Paragraph>
          </Flex>
        </Flex>

        {/* Image */}
        <div
          style={{
            position: "absolute",
            right: 40,
            bottom: 0,
          }}
        >
          <Image
            preview={false}
            src={(researchPlanSvg as { src: string }).src}
            width={300}
            height={300}
            alt="Research Planner image"
          />
        </div>
      </Card>
    </>
  );
};
