import { WithSidebarMenuLayout } from "components/routing/page/layouts/with-sidebar-menu/with-sidebar-menu-layout";
import MaintenanceTemplate from "pages/maintenance";
import { useEffect } from "react";
import { LoginModal } from "templates/index/login";
import { useImpersonateLogin } from "logic/hooks/impersonate-login";
import { Home } from "./components/home";
import { SignupRequiredAlert } from "../../../components/shared/signup-required-alert/signup-required-alert";
import { handleNewStudy } from "@/store/modules/study/utils/hanlde-new-study";
import { IndexTopBarActions } from "./components/top-bar-actions";

type IndexContainerProps = {
  isMaintenance: boolean;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
  plansEnabled?: boolean;
};

export const IndexContainer: React.FC<IndexContainerProps> = ({
  isMaintenance,
  ragEnabled,
  surveysEnabled,
  plansEnabled,
}) => {
  function Content() {
    const { isImpersonating } = useImpersonateLogin();

    useEffect(() => {
      // handle study reset when user navigates back to the home page through browser back button
      handleNewStudy();
    }, []);

    return (
      <WithSidebarMenuLayout
        ragEnabled={ragEnabled}
        surveysEnabled={surveysEnabled}
        plansEnabled={plansEnabled}
        isImpersonating={isImpersonating}
        topBarActions={<IndexTopBarActions />}
      >
        <Home />
      </WithSidebarMenuLayout>
    );
  }

  return (
    <>
      {isMaintenance ? (
        <MaintenanceTemplate />
      ) : (
        <SignupRequiredAlert>
          <Content />
          <LoginModal />
        </SignupRequiredAlert>
      )}
    </>
  );
};
