import { Modal, Typography } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { WORKSPACE_PLANS_ROUTE } from "templates/workspace-plans/workspace-plans-routes";

interface PlanUpgradeModalProps {
  error: TransportFailure;
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export function PlanUpgradeModal({ error, visible, setVisible }: PlanUpgradeModalProps) {
  const router = useRouter();
  const analytics = getAnalytics();

  const modalCopy = useMemo(() => {
    switch (error) {
      case TransportFailure.CustomScriptTooManyQuestions:
        return {
          title: "Maximum Questions Exceeded",
          text: "Upgrade plan to add more questions.",
        };
      case TransportFailure.InterviewsLimitReached:
        return {
          title: "Maximum Interviews Exceeded",
          text: "Upgrade plan to generate more interviews.",
        };
      case TransportFailure.PlanUpgradeNeeded:
        return {
          title: "Plan Upgrade Needed",
          text: "Upgrade plan to get access to this feature.",
        };
      case TransportFailure.WorkspaceSeatsLimitReached:
        return {
          title: "Seat Limit Exceeded",
          text: "Upgrade plan to invite more members.",
        };
      case TransportFailure.SubscriptionNotFound:
        return {
          title: "Plan Subscription Needed",
          text: "Please subscribe to a paid plan to access this feature.",
        };
      default:
        return {
          title: "Plan Upgrade Needed",
          text: "Upgrade plan to get access to this feature.",
        };
    }
  }, [error]);

  return (
    <Modal
      title={modalCopy.title}
      open={visible}
      width={"30%"}
      maskClosable={false}
      okText={
        error === TransportFailure.SubscriptionNotFound ? "See plans" : "Upgrade subscription"
      }
      onOk={() => {
        analytics.track("upgrade-modal:upgrade");
        setVisible(false);
        router.push(WORKSPACE_PLANS_ROUTE.getHref());
      }}
      onCancel={() => {
        analytics.track("upgrade-modal:cancel");
        setVisible(false);
      }}
    >
      <Typography.Paragraph style={{ display: "flex", justifyContent: "space-between" }}>
        {modalCopy.text}
      </Typography.Paragraph>
    </Modal>
  );
}
