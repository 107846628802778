import { useToken } from "@/components/ui-kit/core/token";
import { Spin } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { useAuthContext } from "logic/auth-context";
import { isTransportFailure } from "logic/internals/transports/transported-data/is-transport-failure";
import Head from "next/head";
import { ReactNode, useEffect, useState, useMemo } from "react";
import { Redirect } from "../../redirect/redirect";
import { INDEX_ROUTE } from "@/templates/index/index-routes";

type PageMobileProps = {
  title: string;
  desktop: ReactNode;
  mobile: ReactNode;
  requiresLogin?: boolean;
};

export const PageMobile: React.FC<PageMobileProps> = ({
  title,
  desktop,
  mobile,
  requiresLogin = true,
}) => {
  const auth = useAuthContext();
  const token = useToken();

  const initialScreenSize = typeof window === "undefined" ? undefined : window.innerWidth;

  const userHasAccess = useMemo(
    () => !requiresLogin || auth.session.user,
    [requiresLogin, auth.session.user]
  );

  const [screenSize, setScreenSize] = useState<number | undefined>(initialScreenSize);

  useEffect(() => {
    if (screenSize === undefined) {
      setScreenSize(window.innerWidth);
    }

    let timeout: number | undefined = undefined;

    const resizeListener = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = window.setTimeout(() => {
        setScreenSize(window.innerWidth);
      }, 300);
    };

    window.addEventListener("resize", resizeListener);

    const visibilityListener = () => {
      if (document.visibilityState === "visible") {
        setScreenSize(window.innerWidth);
      }
    };

    document.addEventListener("visibilitychange", visibilityListener);

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }

      window.removeEventListener("resize", resizeListener);
      document.removeEventListener("visibilitychange", visibilityListener);
    };
  }, [screenSize]);

  const isMobile = screenSize === undefined ? false : screenSize < 800;

  return (
    <>
      <Head>
        <title>{`${title} - Synthetic Users: user research without the headaches`}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>

      {auth.session.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: token.marginMD,
            marginBottom: token.marginMD,
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {userHasAccess ? (
            <>{isMobile ? mobile : desktop}</>
          ) : (
            <Redirect href={`${INDEX_ROUTE.getHref()}`} />
          )}

          {isTransportFailure(auth.session.error) && <ErrorFeedback error={auth.session.error} />}
        </>
      )}
    </>
  );
};
