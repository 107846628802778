import { Tabs, theme } from "antd";
import { AuthTabBase, AuthTabType } from "templates/index/login/tab/base";
import { Container } from "components/ui-kit/components/container";
import { useState } from "react";
import tinycolor from "tinycolor2";

export function SignInMobile() {
  function Content() {
    const { token } = theme.useToken();
    const [enableTabs, setEnableTabs] = useState(true);

    const tab = new URLSearchParams(location.search).get("tab");

    const tabs = [
      {
        key: "signup",
        label: `Sign Up`,
        disabled: !enableTabs,
        children: (
          <AuthTabBase type={AuthTabType.SignUp} setEnableTabs={setEnableTabs} isMobile={true} />
        ),
      },
      {
        key: "login",
        label: `Log In`,
        disabled: !enableTabs,
        children: (
          <AuthTabBase type={AuthTabType.Login} setEnableTabs={setEnableTabs} isMobile={true} />
        ),
      },
      {
        key: "sso",
        label: `SSO Log in`,
        disabled: !enableTabs,
        children: (
          <AuthTabBase type={AuthTabType.SSO} setEnableTabs={setEnableTabs} isMobile={true} />
        ),
      }
    ];

    return (
      <div
        style={{
          height: "75vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container style={{ width: "100%", maxWidth: "400px" }}>
          <style jsx global>{`
            .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
              background-color: ${tinycolor(token.colorPrimaryBg).darken(2).toRgbString()};
            }

            .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
              padding-left: ${token.padding}px;
              padding-right: ${token.padding}px;
            }
          `}</style>
          <Tabs
            size="large"
            defaultActiveKey={tab === "login" ? "login" : undefined}
            centered
            destroyInactiveTabPane
            items={tabs}
          />
        </Container>
      </div>
    );
  }

  return <Content />;
}
