import { Col, Row, Typography } from "antd";
import { InterviewModeCard, InterviewModeCardProps } from "./interview-mode-card";
import problemExplorationSvg from "assets/problem_exploration.svg";
import customScriptSvg from "assets/custom-script.svg";
import researchGoalSvg from "assets/research_goal.svg";
import conceptTestingSvg from "assets/concept-testing.svg";
import ethnographicResearchSvg from "assets/ethnographic-research.svg";
import { useAppSelector } from "store/hooks";
import { HeaderCard } from "./header-card";
import { RagCard } from "./rag-card";
import { TutorialsCard } from "./tutorials-cards";
import { useSidebarMenuContext } from "components/routing/page/layouts/with-sidebar-menu/context";
import Link from "next/link";
import { SignupRequiredTrigger } from "components/shared/signup-required-alert/signup-required-trigger";
import { PlanUpgradeModal } from "../../../../../components/shared/plan-upgrade-modal";
import { useMemo, useState } from "react";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useRouter } from "next/router";
import { STUDY_ROUTE } from "templates/studies/study.routes";
import { StudyStrategyCodes } from "store/utils/get-study-strategy-code";
import { useGetReleaseNotesQuery } from "store/modules/release-notes/slice";
import { StudyPlanCard } from "./study-plan-card";
import { useLazyGetWorkspaceByIdQuery } from "@/store/modules/workspaces/slice";
import { PLANS_ROUTE } from "@/templates/plans/plans.routes";

type InterviewModeCard = InterviewModeCardProps & {
  span: number;
  mode: StudyStrategyCodes;
};

export const Home: React.FC = () => {
  const router = useRouter();

  const { ragEnabled, plansEnabled } = useSidebarMenuContext();
  const workspace = useAppSelector((state) => state.workspaces.data.workspace);
  const activeSubscription = useMemo(
    () => workspace?.activeSubscription,
    [workspace?.activeSubscription]
  );

  const interviewsLeft = useMemo(() => {
    return (
      workspace?.subscriptionUsageStats?.interviewsLimit ??
      0 - (workspace?.subscriptionUsageStats?.interviewsUsage ?? 0)
    );
  }, [
    workspace?.subscriptionUsageStats?.interviewsLimit,
    workspace?.subscriptionUsageStats?.interviewsUsage,
  ]);

  const extraInterviewsLeft = useMemo(() => {
    return workspace?.subscriptionUsageStats?.extraStudies ?? 0;
  }, [workspace?.subscriptionUsageStats?.extraStudies]);

  const { data: releaseNotes } = useGetReleaseNotesQuery();

  const [getWorkspaceById] = useLazyGetWorkspaceByIdQuery();

  const { getOngoingWalkthrough } = useOngoingWalkthrough();

  const ethnographicResearchFeatureEnabled = useFeatureFlagEnabled("ethnographic-research");

  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);
  const [clickedInterviewModeCard, setClickedInterviewModeCard] =
    useState<StudyStrategyCodes | null>(null);

  const headerCards = useMemo(
    () =>
      releaseNotes?.slice(0, 3).map((releaseNote) => ({
        text: releaseNote.label,
        url: releaseNote.url,
        span: 8,
      })),
    [releaseNotes]
  );

  const interviewModeCards: InterviewModeCard[] = [
    {
      mode: StudyStrategyCodes.DynamicInterviewMode,
      title: "Research Goal",
      loading: clickedInterviewModeCard === StudyStrategyCodes.DynamicInterviewMode,
      interviewNumber: 1,
      description:
        "Set your research goal and let our multi-agent architecture drive your interviews.",
      imageSrc: (researchGoalSvg as { src: string }).src,
      imageOffset: { x: -30, y: 45 },
      span: 12,
      onClick: () => {
        setClickedInterviewModeCard(StudyStrategyCodes.DynamicInterviewMode);

        router.push({
          pathname: STUDY_ROUTE.getHref(),
          query: { studyType: StudyStrategyCodes.DynamicInterviewMode },
        });
      },
    },
    {
      mode: StudyStrategyCodes.CustomScriptMode,
      title: "Custom Script",
      loading: clickedInterviewModeCard === StudyStrategyCodes.CustomScriptMode,
      interviewNumber: 2,
      description: "Use your own questions (up to 10).",
      imageSrc: (customScriptSvg as { src: string }).src,
      imageOffset: { x: 30, y: 25 },
      span: 12,
      onClick: () => {
        setClickedInterviewModeCard(StudyStrategyCodes.CustomScriptMode);

        router.push({
          pathname: STUDY_ROUTE.getHref(),
          query: { studyType: StudyStrategyCodes.CustomScriptMode },
        });
      },
    },
    {
      mode: StudyStrategyCodes.ProblemExplorationMode,
      title: "Problem Exploration",
      interviewNumber: 3,
      loading: clickedInterviewModeCard === StudyStrategyCodes.ProblemExplorationMode,
      description:
        "You wish to explore the pains and needs of an audience as you look for opportunities.",
      imageSrc: (problemExplorationSvg as { src: string }).src,
      imageOffset: { x: 30, y: 25 },
      span: 12,
      onClick: () => {
        setClickedInterviewModeCard(StudyStrategyCodes.ProblemExplorationMode);

        router.push({
          pathname: STUDY_ROUTE.getHref(),
          query: { studyType: StudyStrategyCodes.ProblemExplorationMode },
        });
      },
    },
    {
      mode: StudyStrategyCodes.SolutionFeedbackMode,
      title: "Concept Testing",
      interviewNumber: 4,
      loading: clickedInterviewModeCard === StudyStrategyCodes.SolutionFeedbackMode,
      description:
        "You want to validate a concept with a particular audience. This is the best interview type for it.",
      imageSrc: (conceptTestingSvg as { src: string }).src,
      span: 12,
      onClick: () => {
        setClickedInterviewModeCard(StudyStrategyCodes.SolutionFeedbackMode);

        router.push({
          pathname: STUDY_ROUTE.getHref(),
          query: { studyType: StudyStrategyCodes.SolutionFeedbackMode },
        });
      },
    },
  ];

  if (ethnographicResearchFeatureEnabled) {
    interviewModeCards.push({
      mode: StudyStrategyCodes.EthnographicResearchMode,
      title: "Ethnographic Research",
      loading: clickedInterviewModeCard === StudyStrategyCodes.EthnographicResearchMode,
      interviewNumber: 5,
      description:
        "Systematically studying people from the subject's perspective to understand their everyday lives and practices.",
      imageSrc: (ethnographicResearchSvg as { src: string }).src,
      span: 12,
      onClick: () => {
        setClickedInterviewModeCard(StudyStrategyCodes.EthnographicResearchMode);

        router.push({
          pathname: STUDY_ROUTE.getHref(),
          query: { studyType: StudyStrategyCodes.EthnographicResearchMode },
        });
      },
    });
  }

  return (
    <>
      <PlanUpgradeModal
        error={
          interviewsLeft <= 0
            ? TransportFailure.InterviewsLimitReached
            : TransportFailure.SubscriptionNotFound
        }
        visible={showPlanUpgradeModal}
        setVisible={setShowPlanUpgradeModal}
      />
      <Row style={{ padding: 32 }} gutter={[16, 16]}>
        {/* Header Cards */}
        {headerCards?.map((card, i) => (
          <Col key={`video-card-${i}`} span={card.span}>
            <Link href={card.url} rel="noopener noreferrer" target="_blank">
              <HeaderCard text={card.text} />
            </Link>
          </Col>
        ))}

        {plansEnabled && (
          <Col span={24}>
            <StudyPlanCard
              onClick={async () => {
                if (
                  (!activeSubscription && !getOngoingWalkthrough()) ||
                  interviewsLeft + extraInterviewsLeft <= 0
                )
                  return setShowPlanUpgradeModal(true);

                await getWorkspaceById({ workspaceId: workspace?.id ?? "" });

                router.push(PLANS_ROUTE.getHref("new"));
              }}
            />
          </Col>
        )}

        {/* Qualitative Research */}
        {/* <Col span={24} style={{ marginBlock: 16 }}>
          <Typography.Title level={5} style={{ marginBottom: 0, marginLeft: 24, fontWeight: 700 }}>
            Qualitative Research
          </Typography.Title>
        </Col> */}
        {interviewModeCards.map((card, i) => (
          <Col key={`interview-mode-selector-card-${i}`} span={card.span}>
            <SignupRequiredTrigger>
              <InterviewModeCard
                title={card.title}
                loading={card.loading}
                interviewNumber={card.interviewNumber}
                description={card.description}
                imageSrc={card.imageSrc}
                onClick={async () => {
                  if ((!activeSubscription && !getOngoingWalkthrough()) || interviewsLeft <= 0)
                    return setShowPlanUpgradeModal(true);

                  await getWorkspaceById({ workspaceId: workspace?.id ?? "" });
                  card.onClick();
                }}
                imageOffset={card.imageOffset}
                imageSize={card.imageSize}
              />
            </SignupRequiredTrigger>
          </Col>
        ))}
        <Col span={12}>{ragEnabled && <RagCard />}</Col>

        {/* Quantitative Research */}
        {/* <Col span={24} style={{ marginBlock: 16 }}>
          <Typography.Title level={5} style={{ marginBottom: 0, marginLeft: 24, fontWeight: 700 }}>
            Quantitative Research
          </Typography.Title>
        </Col>
        <Col span={12}>
          <SurveysCard />
        </Col> */}

        {/* Tutorials */}
        <Col span={24} style={{ marginBlock: 16 }}>
          <Typography.Title level={5} style={{ marginBottom: 0, marginLeft: 24, fontWeight: 700 }}>
            Tutorials
          </Typography.Title>
        </Col>
        <Col span={24}>
          <TutorialsCard />
        </Col>
      </Row>
    </>
  );
};
