import { Alert, Space, Typography } from "antd";
import { useToken } from "components/ui-kit/core/token";

export function EmptyContainerMobile() {
  const token = useToken();
  const urlLink = "https://www.youtube.com/embed/LCiv7lZ3oh4";
  return (
    <div style={{ padding: token.padding }}>
      <Alert
        showIcon
        type="warning"
        description={
          <div>
            <>
              <p>Our mobile is for consumption of previously run studies.</p>
              <p>To generate (and be amazed) use the desktop version.</p>
            </>
          </div>
        }
      />
      <Typography.Title level={4} style={{ marginTop: token.margin }}>
        Synthetic users University
      </Typography.Title>
      <Space
        direction="vertical"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <iframe
          src={urlLink}
          allowFullScreen
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          style={{ width: "435px", height: "250px", border: "none" }}
        ></iframe>
      </Space>
    </div>
  );
}
